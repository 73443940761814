<template>
  <div class="vf-video-card" @click="handleClick">
    <!-- 视频 -->
    <div class="cover" @mouseover="handleMouseOver" @mouseleave="handleMouseLeave">
      <img class="coverimg" :id="`coverImg_${data.object_id}`" :src="data.watermark_file+'?vframe/jpg/offset/1/w/280/h/180'" />
      <i v-if="data && data.object_id == currentId && loading" class="loading el-icon-loading"></i>
      <video
        v-if="data && data.object_id == currentId"
        ref="video"
        loop
        muted
        autoplay
        playsinline
        @loadedmetadata="loaddata"
        :poster="data.watermark_file+'?vframe/jpg/offset/1/w/280/h/180'">
        <source :src="data.watermark_file" />
      </video>
    </div>
    <!-- 详情 -->
    <div class="info">
      <p class="info-title">
        <span style="white-space: nowrap; overflow:hidden; text-overflow: ellipsis" v-html="brightenKeyword(data.name, keywordName)">{{ data.name }}</span>
<!--        <img v-if="data.is_recommend === 1" :src="require('@/assets/svg/hot-badge.svg')" />-->
        <img v-if="data.is_show_hot === 1" :src="require(`@/assets/svg/${ orderNo == 2 && data.is_show_hot === 1 ? 'hot-badge.svg' : 'new-icon.svg'}`)" />
      </p>
      <p class="info-tag">
        <Tag
          mode="text"
          plain
          v-for="(tagItem, tagIndex) in data.label?.length
            ? data.label.slice(0, 4)
            : []"
          v-html="brightenKeyword(tagItem.name, keywordName)"
          :key="tagIndex">{{ tagItem.name }}</Tag>
      </p>
      <div class="info-operation">
        <p>
          <img
            :src="require(`@/assets/svg/${data.is_collect === 1 ? 'collected' : 'collect'}.svg`)"
            @click.stop.prevent="handleVideoCollect(data)"
          />
          <img
            :src="require('@/assets/svg/share-gray.svg')"
            @click.stop="handleShare('', data.object_id, 4, data.name)"
          />
          <!-- 下载 -->
          <img
            :src="require('@/assets/svg/download-gray.svg')"
            @click.stop.prevent="handleDownload({ object_id: data.object_id, type: 4, name: data.name })"
          />
        </p>
        <div class="car"  @click.stop.prevent="handleAddGoodsToCart({ object_id: data.object_id, type: 4, name: data.name })">加入购物车</div>
      </div>
    </div>
  </div>
</template>
<script>
import Tag from './Tag'
import Buttons from '@/layout/mixins/Buttons'
import { mapGetters } from 'vuex'
import {getToken} from "@/utils/author";

export default {
  name: 'VideoCard',
  components: { Tag },
  props: {
    data: Object,
    collected: null,
    keywordName: {
      default: ''
    },
    orderNo: {
      type: Number
    }
  },
  mixins: [Buttons],
  data(){
    return{
      loading: true,
      currentId: null,
      is_collected: 0
    }
  },
  // watch: {
  //   data() {
  //     this.$refs.video.load()
  //   }
  // },
  computed:{
    ...mapGetters({
      userInfo: 'userInfo'
    }),
  },
  created() {
    this.is_collected = this.collected;
  },
  methods: {
    /**
     * 搜索关键字高亮 判断是多组关键词还是一组关键词
     */
    brightenKeyword(val, keyword) {
      if(typeof(keyword) == 'string'){
        var Reg = new RegExp(keyword, 'g');
        val = val+''
        if (val) {
            return val.replace(Reg, `<span style="color:red">${keyword}</span>`);
        }
      }else{
        keyword.map((keyitem)=>{
          var Regs = new RegExp(keyitem, 'g');
          val = val+''
          if (val) {
            val = val.replace(Regs, `<span style="color:red">${keyitem}</span>`);
          }
        })
        return val
      }
    },
    /**
     * 点击视频
     */
     async handleVideoCollect(item) {
      if (!(getToken() && getToken() !== '')) {
        this.$store.dispatch('user/openLogin')
        return
      }
      item.is_collect = item.is_collect === 0 ? 1 : 0
      await this.handleCollect(item.object_id, 4, {
        ...item,
        type: 4,
        labels: item.label
      }, false)
      this.$emit('change', item)
    },
    /**
     * 点击视频
     */
    handleClick() {
      this.$emit('onClick')
    },

    loaddata(){
      this.loading = false;
    },

    /**
     * 鼠标移入视频播放
     */
    async handleMouseOver() {
      let res = await fetch(`${this.data.watermark_file}?vframe/jpg/offset/1/w/280/h/180`);
      if(res.ok){
        let dom = document.getElementById(`coverImg_${this.data.object_id}`);
        dom.style.display = "none";
        this.currentId = this.data.object_id;
        // this.$refs.video && this.$refs.video.play();
      }
    },

    /**
     * 鼠标移出视频暂停
     */
    handleMouseLeave() {
      let dom = document.getElementById(`coverImg_${this.data.object_id}`);
      dom.style.display = "block";
      let el = document.getElementsByClassName("coverimg");
      for(let i=0;i<el.length;i++){
        el[i].style.display = "block";
      }
      this.currentId = null;
      this.loading = true;
      // this.$refs.video && this.$refs.video.load();
    },
  }
}
</script>
<style lang="scss" scoped>
.vf-video-card {
  @include size(280px, 189px);
  position: relative;
  cursor: pointer;
  .cover {
    @include size(100%, 100%);
    border-radius: 12px;
    overflow: hidden;
    position: relative;
    z-index: 0;
    video {
      @include size(100%, 100%);
      object-fit: fill;
    }
    .coverimg{
      @include size(100%, 100%);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      display: block;
      border-radius: 12px;
      border: 1px solid #d8d8d8;
    }
  }
  .info {
    @include size(230px, 120px);
    border-radius: 24px 0px 24px 24px;
    background: $base-color;
    box-shadow: 0px 8px 12px 0px rgba(33, 48, 83, 0.07);
    padding: 15px 14px;
    position: absolute;
    right: 8px;
    bottom: calc(26px - 120px);
    &-title {
      @include font-bold($size: $font-size-md);
      @include center-middle-flex($justify: flex-start);
      line-height: 25px;
      img {
        @include size(28px, 20px);
        margin-top: -12px;
        margin-left: 8px;
      }
    }
    &-tag {
      margin-top: 2px;
      line-height: 20px;
      height: 20px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .vf-tag-text {
        @include font-normal($color: rgba($font-theme-color, 0.48));
        margin-right: 8px;
      }
      .vf-tag-text:nth-last-of-type(1) {
        margin-right: 0;
      }
    }
    &-operation {
      @include center-middle-flex($justify: space-between);
      margin-top: 20px;
      p {
        display: flex;
        align-items: center;
        img {
          margin-right: 18px;
        }
        img:nth-last-of-type(1) {
          margin-right: 0;
        }
      }
      .car {
        @include size(72px, 24px);
        @include center-middle-flex;
        border-radius: 2px;
        border: 1px solid $theme-color;
        @include font-normal($size: $font-size-xs, $color: $theme-color);
      }
    }
  }
  .loading{
    display: inline-block;
    font-size: 30px;
    position: absolute;
    top: 45%;
    left: 45%;
    color: #fff;
  }
}
</style>
